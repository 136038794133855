import { graphql } from 'msw'

const verifyPhone = [
	graphql.mutation('sendPhoneCode', (req, res, ctx) => {
		return res(
			ctx.data({
				sendPhoneCode: {
					ok: true,
					errors: []
				}
			})
		)
	}),
	graphql.mutation('validatePhoneCode', (req, res, ctx) => {
		const { code } = req.variables

		if (code === '12345') {
			return res(
				ctx.data({
					validatePhoneCode: {
						ok: true,
						errors: []
					}
				})
			)
		}

		return res(
			ctx.data({
				validatePhoneCode: {
					ok: false,
					errors: [
						{
							fieldName: 'code',
							errors: ['Incorrect verification code. Please try again.']
						}
					]
				}
			})
		)
	}),
	graphql.mutation('updatePhoneNumber', (req, res, ctx) => {
		const { phoneNumber } = req.variables

		if (phoneNumber === '+380658624512') {
			return res(
				ctx.data({
					updatePhoneNumber: {
						ok: false,
						errors: [
							{
								fieldName: 'phoneNumber',
								errors: ['This phone number is already in use.']
							}
						]
					}
				})
			)
		}

		if (phoneNumber === '+380658127534') {
			return res(
				ctx.data({
					updatePhoneNumber: {
						ok: true,
						errors: []
					}
				})
			)
		}

		return res(
			ctx.data({
				updatePhoneNumber: {
					ok: false,
					errors: [
						{
							fieldName: 'phoneNumber',
							errors: ['Phone number is not valid']
						}
					]
				}
			})
		)
	})
]

export default verifyPhone
