import gql from 'graphql-tag'

export const IS_AUTHENTICATED = gql`
	query user {
		user {
			id
			postCode
			country {
				id
				name
				icon
			}
			city
			phoneNumber
			firstName
			lastName
			email
			address
			licences(inProgress: true) {
				code
				status
				school {
					name
				}
				licenceType
			}
			extraLicences {
				totalAmount
				licences {
					licenceType
					quantity
					price
				}
			}
			allowParentControlRecreationTime
			isAuthenticated
			isSuperadmin
			isAdmin
			isExtra
			hasExtra
			isRegistered
			isEmailVerified
			isPhoneNumberVerified
			extraEmails
		}
	}
`

export const GET_ANONYMOUS_USER = gql`
	query anonymousUser {
		anonymousUser {
			licences {
				currency
				totalAmount
				recurringInterval
				licences {
					currency
					licenceType
					quantity
					price
				}
				codes
			}
			isPaid
		}
	}
`
