import { useNavigate } from 'react-router'
import { Button } from 'src/shared/ui/Button'

export const ErrorFallback = ({ error, resetErrorBoundary }) => {
	const navigate = useNavigate()

	const reloadPage = () => {
		navigate(0)
	}
	return (
		<div role="alert" className="error-boundary">
			<p className="error-boundary__title">Something went wrong:</p>
			<pre className="error-boundary__message">{error.message}</pre>
			<Button onClick={resetErrorBoundary} color="primary">
				Try again
			</Button>
			<p className="error-boundary__subtitle">
				Or{' '}
				<button onClick={reloadPage} className="error-boundary__reload">
					reload
				</button>{' '}
				the page
			</p>
		</div>
	)
}
