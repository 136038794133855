import { CustomSelectValue } from 'src/shared/ui/SimpleSelect/types'
import { defaultFilters } from 'src/features/ParentFilters/options'
import { createTableStore } from 'src/shared/helpers/tableStore'

export type StatusPayload = 'ALL' | 'ACTIVATED' | 'PENDING'
export type LicenceStatus =
	| 'OPEN'
	| 'IN_PROGRESS'
	| 'PROTECTED'
	| 'UNPROTECTED'
	| 'EXPIRED'
	| 'default'
export type LicenceType = 'PRE_PAID' | 'EXTRA' | 'default'

type TableStoreFilters = {
	status: StatusPayload
	school: CustomSelectValue
	policy: CustomSelectValue
	licenceStatus: CustomSelectValue<LicenceStatus>
	licenceType: CustomSelectValue<LicenceType>
}
type TableStoreKeys = 'name' | 'email'
type TableSelectedRow = { id: string; status: 'Activated' | 'Pending' }
type TableCustomMethods = 'setParentFilters'

export const useParentsTableStore = createTableStore<
	TableStoreKeys,
	TableStoreFilters,
	TableSelectedRow,
	TableCustomMethods
>({
	filters: ['status', 'school', 'policy', 'licenceStatus', 'licenceType'],
	sort: ['name', 'email'],
	hasSelectedRows: true,
	defaultOrderBy: 'NAME_AND_EMAIL',
	defaultFilters: {
		status: 'ALL',
		school: defaultFilters.school,
		policy: defaultFilters.policy,
		licenceStatus: defaultFilters.licenceStatus,
		licenceType: defaultFilters.licenceType
	},
	customMethods: (set) => ({
		setParentFilters: ({ school, policy, licenceStatus, licenceType }) =>
			set(() => ({
				school,
				policy,
				licenceStatus,
				licenceType,
				selectedRows: []
			}))
	})
})
