import { create } from 'zustand'
import { capitalizeString } from 'src/shared/helpers/common'
import {
	FilterType,
	TableConfig,
	BaseTableActions,
	BaseTableState,
	SelectTableState,
	Row
} from './types'

export function createTableStore<
	SortKeys extends string,
	Filters extends FilterType = Record<string, any>,
	SelectedRow = Record<string, never>,
	CustomActions extends string = string
>({
	filters = [],
	sort = [],
	defaultFilters = {},
	hasSelectedRows = false,
	initialPerPage = 10,
	defaultOrderBy = null,
	defaultSortBy = 'ASC',
	customMethods
}: TableConfig<Filters, SortKeys, SelectedRow>) {
	type State = BaseTableState<Filters, SortKeys, SelectedRow>
	type Actions = BaseTableActions<Filters, SortKeys, SelectedRow, CustomActions>

	const initialState = {
		page: 1,
		perPage: initialPerPage,
		search: '',
		sortBy: defaultSortBy,
		orderBy: defaultOrderBy,
		shouldUpdate: false,
		...Object.fromEntries(filters.map((filter) => [filter, defaultFilters?.[filter] ?? null]))
	} as const as State

	return create<State & Actions>((set) => {
		const filterSetters = Object.fromEntries(
			filters.map((filter) => [
				`set${capitalizeString(String(filter))}`,
				(value: Filters[typeof filter]) =>
					set((state) => ({
						...state,
						[filter]: value,
						page: 1,
						...(hasSelectedRows && { selectedRows: [] })
					}))
			])
		) as Partial<Actions>

		const sortSetters: Partial<Actions> = sort.reduce((acc, sortKey) => {
			const orderBy = sortKey.toUpperCase()
			return {
				...acc,
				[`setSortBy${capitalizeString(sortKey)}Asc`]: () =>
					set((state) => ({
						...state,
						sortBy: 'ASC',
						orderBy,
						...(hasSelectedRows && { selectedRows: [] })
					})),
				[`setSortBy${capitalizeString(sortKey)}Desc`]: () =>
					set((state) => ({
						...state,
						sortBy: 'DESC',
						orderBy,
						...(hasSelectedRows && { selectedRows: [] })
					}))
			}
		}, {}) as Partial<Actions>

		const baseActions = {
			setPage: (page: number) =>
				set((state) => ({ ...state, page, ...(hasSelectedRows && { selectedRows: [] }) })),
			setPerPage: (perPage: number) =>
				set((state) => ({
					...state,
					perPage,
					page: 1,
					...(hasSelectedRows && {
						selectedRows: perPage > state.perPage ? state.selectedRows : []
					})
				})),
			setSearch: (search: string) =>
				set((state) => ({
					...state,
					search,
					page: 1,
					...(hasSelectedRows && { selectedRows: [] })
				})),
			setShouldUpdate: (shouldUpdate: boolean) =>
				set((state) => ({ ...state, shouldUpdate })),
			resetStore: () => set(() => initialState as State & Actions),
			resetFilters: () =>
				set((state) => ({
					...state,
					page: 1,
					...Object.fromEntries(
						filters.map((filter) => [
							filter,
							defaultFilters[filter] ?? { value: '', label: '' }
						])
					),
					...(hasSelectedRows && { selectedRows: [] })
				}))
		} as Partial<BaseTableActions<Filters, SortKeys, SelectedRow, CustomActions>>

		const selectedRow: SelectTableState = {
			selectedRows: [],
			setSelectedRows: (rows: Row[]) => set((state) => ({ ...state, selectedRows: rows }))
		}
		const initialSelectedRowState = hasSelectedRows ? selectedRow : {}

		return {
			...initialState,
			...baseActions,
			...filterSetters,
			...sortSetters,
			...initialSelectedRowState,
			...customMethods?.(set)
		} as State & Actions
	})
}
