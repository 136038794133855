import React, { Suspense, useEffect } from 'react'
import {
	createBrowserRouter,
	createRoutesFromElements,
	Route,
	RouterProvider,
	useNavigate,
	Outlet
} from 'react-router-dom'
import { addMinutes, isFuture } from 'date-fns'
import ROUTES_NAME from 'src/shared/router/routesNames'
import { QueryClientProvider } from '@tanstack/react-query'
import { PageLoading } from 'src/shared/ui/PageLoading'
import { SnackbarProvider } from 'notistack'
import { queryClient } from 'src/app/graphql/client'
import { ErrorBoundary } from 'react-error-boundary'
import { ErrorFallback } from 'src/features/ErrorFallback'
import { Metadata } from './features/Metadata'
import { PrivateRoutes } from './shared/guards/PrivateRoutes'
import { FinishRegisterRoutes } from './shared/guards/FinishRegisterRoutes'
import { routes } from './shared/router'
import { Snackbar } from './shared/ui/Snackbar'
import BackgroundExport from './features/BackgroundExport'

const Root = () => {
	const navigate = useNavigate()

	useEffect(() => {
		const underMaintenance = localStorage.getItem('underMaintenance')

		if (isFuture(addMinutes(new Date(+underMaintenance), 2))) {
			queryClient.cancelQueries()
			navigate(ROUTES_NAME.UNDER_MAINTENANCE)
		}
	}, [])

	return (
		<QueryClientProvider client={queryClient}>
			<ErrorBoundary FallbackComponent={ErrorFallback}>
				<SnackbarProvider
					anchorOrigin={{ horizontal: 'center', vertical: 'top' }}
					maxSnack={1}
					Components={{
						error: Snackbar,
						success: Snackbar,
						default: Snackbar,
						info: Snackbar
					}}
				>
					<BackgroundExport />
					<Metadata />
					<Suspense fallback={<PageLoading />}>
						<Outlet />
					</Suspense>
				</SnackbarProvider>
			</ErrorBoundary>
		</QueryClientProvider>
	)
}

const router = createBrowserRouter(
	createRoutesFromElements(
		<Route element={<Root />}>
			{routes.map(({ path, Element, privateRoute, checkFulfillment, superAdminOnly }) => {
				if (privateRoute) {
					return (
						<Route
							element={<PrivateRoutes superAdminOnly={superAdminOnly} />}
							key={path}
						>
							<Route path={path} element={<Element />} />
						</Route>
					)
				}
				if (checkFulfillment) {
					return (
						<Route element={<FinishRegisterRoutes />} key={path}>
							<Route path={path} element={<Element />} />
						</Route>
					)
				}
				return <Route path={path} element={<Element />} key={path} />
			})}
		</Route>
	)
)

const App = () => {
	return <RouterProvider router={router} />
}

export default App
