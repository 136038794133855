import React from 'react'
import { createRoot } from 'react-dom/client'
import 'src/shared/styles/main.scss'
import App from './App'
import './index.css'
import { worker } from './shared/mocks/browser'

async function bootstrapApp() {
	if (process.env.REACT_APP_TEST_MODE) {
		await worker.start()
	}
	const root = createRoot(document.getElementById('root'))
	root.render(<App />)
}

bootstrapApp()
