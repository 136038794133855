import { format } from 'date-fns'
import { BackendErrorsType, DateRangeState } from 'src/shared/types/common'
import { formatInTimeZone } from 'date-fns-tz'

export const isEmptyObject = (obj) => {
	// eslint-disable-next-line
    for (let prop in obj) {
		if (Object.prototype.hasOwnProperty.call(obj, prop)) {
			return false
		}
	}
	return true
}

export const isNullOrUndefined = (value) => {
	return value === null || value === undefined
}

export const copyTextToClipboard = async (text) => {
	if ('clipboard' in navigator) {
		return navigator.clipboard.writeText(text)
	}
	return document.execCommand('copy', true, text)
}

export function capitalizeString(string: string) {
	return string.charAt(0).toUpperCase() + string.slice(1)
}

export const serializeNonFieldErrors = (errors: BackendErrorsType): string => {
	if (!errors) {
		return null
	}
	const nonFieldErrors = errors.find((error) => error.fieldName === 'nonFieldErrors')
	if (nonFieldErrors) {
		return nonFieldErrors.errors.join('. ')
	}
	return null
}

export const emptyStringToNull = (value, originalValue) => {
	if (typeof originalValue === 'string' && originalValue === '') {
		return null
	}
	return value
}

export const transformPolicies = (policies: any) => {
	return policies?.map((policy: any) => {
		return {
			id: policy?.id,
			label: policy?.name,
			value: policy?.id
		}
	})
}

const censorWord = (str: string): string => {
	return str.substring(0, 2) + '*'.repeat(str.length - 2)
}

export const censorEmail = (email: string) => {
	let arr = email.split('@')
	return `${censorWord(arr[0])}*${arr[1]}`
}

export const getISOStringWithTimeZone = (date: Date, timeZone: string) => {
	const dateString = formatInTimeZone(date, timeZone, 'yyyy-MM-dd')
	const timeString = formatInTimeZone(date, timeZone, 'HH:mm:ss')
	const zone = formatInTimeZone(date, timeZone, 'XXX')

	return `${dateString}T${timeString}${zone}`
}

export const getTheEndOfDate = (date: Date = new Date()) => new Date(date.setHours(23, 59, 59, 59))
export const getFormattedDate = (date: Date) => format(date, 'MM/dd/yyy')
export const getResetDate = (date: Date = new Date()) => new Date(date.setHours(0, 0, 0, 0))

export const getIsoDateRange = (
	{ startDate, endDate }: DateRangeState,
	currentTimeZone: string
) => ({
	isoStarDate: startDate ? getISOStringWithTimeZone(startDate, currentTimeZone) : null,
	isoEndDate: endDate ? getISOStringWithTimeZone(endDate, currentTimeZone) : null
})
