import { graphql } from 'msw'
import { isBoolean } from 'lodash'
import articles from './fixtures/articles.json'

const adminDashboardArticles = [
	graphql.query('articles', (req, res, ctx) => {
		const { orderBy, sortBy, search, isPublished } = req.variables

		if (isBoolean(isPublished)) {
			return res(
				ctx.data(isPublished ? articles.data.onlyPublished : articles.data.onlyDrafts)
			)
		}

		if (search) {
			return res(
				ctx.data(
					search === 'Test 2' ? articles.data.searchResult : articles.data.emptySearch
				)
			)
		}

		if (orderBy === 'TITLE') {
			return res(
				ctx.data(sortBy === 'ASC' ? articles.data.titleAsc : articles.data.titleDesc)
			)
		}

		if (orderBy === 'IS_PUBLISHED') {
			return res(
				ctx.data(sortBy === 'ASC' ? articles.data.statusAsc : articles.data.statusDesc)
			)
		}

		return res(ctx.data(articles.data.default))
	})
]

export default adminDashboardArticles
