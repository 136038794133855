import { graphql } from 'msw'
import countries from './fixtures/countries.json'

const parentRegistration = [
	graphql.query('countries', (_, res, ctx) => res(ctx.data(countries))),
	graphql.mutation('registerUser', (req, res, ctx) => {
		const { phoneNumber, ...rest } = req.variables
		if (phoneNumber === '+1111111111') {
			return res(
				ctx.data({
					registerUser: {
						ok: false,
						errors: [
							{
								fieldName: 'phoneNumber',
								errors: ['Number is already taken']
							}
						]
					}
				})
			)
		}
		return res(
			ctx.data({
				registerUser: {
					ok: true,
					errors: [],
					user: {
						id: '1',
						phoneNumber,
						isAuthenticated: true,
						isAdmin: false,
						isSuperadmin: false,
						isRegistered: true,
						licences: [],
						...rest
					}
				}
			})
		)
	}),

	graphql.mutation('createUser', (req, res, ctx) => {
		const { phoneNumber, email, ...rest } = req.variables

		if (phoneNumber === '+1111111111' && email === 'existing@email.com') {
			return res(
				ctx.data({
					createUser: {
						ok: false,
						user: null,
						licences: [],
						errors: [
							{
								fieldName: 'phoneNumber',
								errors: ['User with this phone number already exists.']
							},
							{
								fieldName: 'email',
								errors: ['User with this email already exists.']
							}
						]
					}
				})
			)
		}

		return res(
			ctx.data({
				createUser: {
					ok: true,
					errors: [],
					licences: [
						{
							activationDate: '21 Sep 2023',
							code: 'nnSCYdkK',
							isValid: false,
							profileCode: null,
							status: 'IN_PROGRESS',
							__typename: 'LicenceType'
						}
					],
					user: {
						id: '1',
						phoneNumber,
						email,
						licences: [],
						extraEmails: [],
						hasExtra: false,
						isExtra: false,
						isAuthenticated: true,
						isAdmin: false,
						isSuperadmin: false,
						isRegistered: true,
						...rest,
						country: {
							...rest.country,
							id: '1',
							name: 'UK'
						},
						__typename: 'UserType'
					},
					__typename: 'CreateUserMutation'
				}
			})
		)
	})
]

export default parentRegistration
