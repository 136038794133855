import classNames from 'classnames'
import React from 'react'
import { Loader } from 'src/shared/ui/Loader'
import styles from './Button.module.scss'

type ButtonProps = {
	children: any
	onClick?: () => void
	color?: 'primary' | 'secondary' | 'outlined' | 'empty' | 'danger' | 'underlined'
	block?: boolean
	classname?: string
	isDisable?: boolean
	isLoading?: boolean
	type?: 'button' | 'submit' | 'reset'
	loaderColor?: 'black' | 'white' | 'grey'
	hoverTitle?: string
	dataTestId?: string
}

export const Button: React.FC<ButtonProps> = ({
	children,
	onClick,
	color,
	block,
	classname,
	isLoading,
	isDisable = false,
	type = 'button',
	loaderColor,
	hoverTitle = '',
	dataTestId
}) => {
	const getClass = classNames(styles.btn, classname, styles[color], {
		[styles._block]: block
	})

	return (
		<button
			onClick={onClick}
			className={getClass}
			type={type}
			disabled={isDisable || isLoading}
			title={hoverTitle}
			data-testid={dataTestId}
		>
			{isLoading ? <Loader width="20px" color={loaderColor} center /> : children}
		</button>
	)
}

export default Button
