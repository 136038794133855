import { createQueryKeyStore, createQueryKeys } from '@lukemorales/query-key-factory'

export const userkeys = createQueryKeyStore({
	user: null
})

export const anonymousUser = createQueryKeys('anonymousUser')
export const createUser = createQueryKeys('createUser')
export const confirmUserEmailKey = createQueryKeys('confirmUserEmailKey')
export const confirmUserNewEmailKey = createQueryKeys('confirmUserNewEmailKey')
export const verifyUserKey = createQueryKeys('loginUserByToken')
export const resendEmailKey = createQueryKeys('resendEmailKey')
